<template>
    <div id="home" class="home">
        <div class="header flexRow">
            <div class="headerTitle">扬州市芃雨医疗科技有限公司</div>
            <div class="headerTab flexRow">
                <div :class="[{'selected': tabSelected === index},'headerTabInner']" v-for="(item,index) in tabs"
                     :key="index" @click="gotoTab(index)">{{ item }}
                </div>
            </div>
        </div>
        <div style="height: 120px"></div>
        <div class="headerImg">
            <el-carousel indicator-position="none" arrow="never" height="100%" :interval=1500>
                <el-carousel-item v-for="(item, index) in headerImgArr" :key="index">
                    <img :src="item" alt=""/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="introduceDiv">
            <div class="shadowFont">ABOUT US</div>
            <div class="greenFont">公司介绍</div>
        </div>
        <div class="introduceOuter">
            <img src="../static/image/introduce.png"/>
            <div>
                <div class="introduceName">扬州市芃雨医疗科技有限公司</div>
                <div class="introduceLine"></div>
                <div class="introduceContent">
                    扬州市芃雨医疗科技有限公司，主要经营范围有：医疗器械技术开发、技术咨询、技术服务、技术转让，一类、二类、三类医疗器械、生物制品，化妆品，橡胶制品、塑料制品，卫生用品、办公用品，针织品，五金交电销售。
                </div>
                <div class="aboutMoreButton" @click="gotoMore">了解更多</div>
            </div>
        </div>
        <div class="addressHeader">
            <div class="shadowFont addressTop">ADDRESS</div>
            <div class="greenFont">公司地址</div>
        </div>
        <div class="mapOuter">
            <div id="mapContainer" class="mapContainer"></div>
        </div>
        <div class="contactOuter">
            <div class="shadowFont">CONTACT US</div>
            <div class="greenFont">联系我们</div>
            <div class="contactInner">
                <div class="contactContent">
                    <div class="roundOuterOuter">
                        <div class="roundOuter">
                            <div style="width: 3rem">联系电话</div>
                        </div>
                    </div>
                    <div class="squareOuter">
                        <div class="iconfont icon-dianhua iconDianHua"></div>
                        <div class="textDianHua">18061179040</div>
                    </div>
                </div>
                <div class="contactContent">
                    <div class="roundOuterOuter">
                        <div class="roundOuter">
                            <div style="width: 4.5rem">联系人</div>
                        </div>
                    </div>
                    <div class="squareOuter">
                        <div class="textDianHua">王亚星 总经理</div>
                    </div>
                </div>
                <div class="contactContent">
                    <div class="roundOuterOuter">
                        <div class="roundOuter">
                            <div style="width: 3rem">邮编</div>
                        </div>
                    </div>
                    <div class="squareOuter">
                        <div class="iconfont icon-youbian iconYouBian"></div>
                        <div class="textDianHua">225109</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomOuter">
            <div class="bottomContent">
                <div class="contentText">
                    <div class="iconfont icon-dingwei bottomIcon"></div>
                    <div>江苏省扬州市广陵区头桥镇通达路130号</div>
                </div>
                <div class="contentText">
                    <div class="iconfont icon-dianhua bottomIcon"></div>
                    <div>18061179040</div>
                </div>
                <div class="contentText">
                    <div class="iconfont icon-youbian bottomIcon"></div>
                    <div>225109</div>
                </div>
            </div>
            <div class="bottomName"><a style="color: #CACACA;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备2023050476号-1 版权所有 ©️扬州市芃雨医疗科技有限公司</a></div>
        </div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

// @ is an alias to /src

export default {
    name: 'Home',
    data() {
        return {
            tabSelected: 0,
            tabs: ['公司首页', '公司介绍', '公司地址', '联系我们'],
            headerImgArr: [
                require('@/static/image/header1.png'),
                require('@/static/image/header2.png'),
                require('@/static/image/header1.png'),
                require('@/static/image/header2.png')
            ]
        }
    },
    components: {},
    methods: {
        gotoMore() {
            this.$router.push("about")
        },
        gotoTab(index) {
            this.tabSelected = index
            let top = 0;
            switch (index) {
                case 0:
                    top = 0;
                    break;
                case 1:
                    top = document.getElementsByClassName("introduceDiv")[0].offsetTop - 120;
                    break;
                case 2:
                    top = document.getElementsByClassName("addressTop")[0].offsetTop - 120 + 36;
                    break;
                case 3:
                    top = document.getElementsByClassName("contactOuter")[0].offsetTop - 120;
                    break;
            }
            document.getElementById("home").scrollTo({
                top: top,
                behavior: 'smooth'
            });
        },
        initMap() {
            window._AMapSecurityConfig = {
                securityJsCode: 'f0483458d14d78b11348628adbe81a70',
            }
            AMapLoader.load({
                "key": "265d8fb260b5bfd5f30198ca0a24398c",              // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "2.0",   // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
                "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                const map = new AMap.Map('mapContainer', {
                    zoom: 15,  //设置地图显示的缩放级别
                    center: [119.648383, 32.323006],  //设置地图中心点坐标
                    // mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
                    // viewMode: '2D'  //设置地图模式
                });
                map.setDefaultCursor('default');
                map.add(new AMap.Marker({
                    position: [119.648383, 32.323006],
                    title: '扬州市芃雨医疗科技有限公司'
                }));

                //构建信息窗体中显示的内容
                var infoWindow;
                var info = [];
                info.push("<p class='input-item'>江苏省扬州市广陵区头桥镇通达路130号</p></div></div>");

                infoWindow = new AMap.InfoWindow({
                    content: info.join("")  //使用默认信息窗体框样式，显示信息内容
                });

                infoWindow.open(map, map.getCenter());

            }).catch(e => {
                console.error(e);
            })
        }
    },
    mounted() {
        this.initMap()
        this.$nextTick(()=>{
            this.$route.query.scrollIndex ? this.gotoTab(this.$route.query.scrollIndex - 1) : null
        })
    }
}
</script>

<style lang="scss" scoped>
.home {
    height: 100%;
    width: 100%;
    overflow: auto;
    transition: scrollTop 2s;
    -webkit-transition: scrollTop 2s; /* Safari */

    #map-container {
        cursor: none;
    }

    /deep/ .amap-logo {
        display: none !important;
    }

    /deep/ .amap-copyright {
        display: none !important;
    }

    .shadowFont {
        color: rgba(54, 169, 206, 0.1);
        font-size: 7rem;
        font-weight: 800;
        margin-top: 4.5rem;
    }

    .greenFont {
        color: #00a9ac;
        font-size: 2.5rem;
        font-weight: bolder;
        margin-top: -3.5rem;
    }

    .contactInner {
        width: 70%;
        margin: 6rem 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .contactContent {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .roundOuterOuter {
                height: 11rem;
                width: 11rem;
                background-color: #FFFFFF;
                border-radius: 50%;
                z-index: 999;
                font-size: 1.5rem;

                .roundOuter {
                    color: #FFFFFF;
                    background-color: #81B4BC;
                    height: calc(100% - 1.4rem);
                    width: calc(100% - 1.4rem);
                    margin: 0.7rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .squareOuter {
                border: 1px solid #000;
                width: 100%;
                aspect-ratio: 397 / 252;
                background-color: #F0F2F5;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: -5.5rem;

                .iconDianHua {
                    font-size: 2rem;
                    margin-top: 3rem;
                    margin-right: 1rem;
                }

                .textDianHua {
                    font-size: 1.5rem;
                    margin-top: 3rem;
                    font-weight: 400;
                }

                .iconYouBian {
                    font-size: 1.2rem;
                    margin-top: 3rem;
                    margin-right: 1rem;
                }
            }
        }
    }

    .flexRow {
        display: flex;
        flex-direction: row;
    }

    .header {
        height: 120px;
        width: calc(80% - 8px);
        padding: 0 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        background-color: #FFFFFF;
        z-index: 999;

        .headerTitle {
            font-weight: 650;
            font-size: 2rem;
        }

        .headerTab {
            align-items: center;
            justify-content: flex-end;

            .headerTabInner {
                font-size: 1rem;
                margin-left: 2rem;
                margin-right: 0.5rem;
                cursor: pointer;
                width: 6rem;
                height: 2.5rem;
                border-radius: 2rem;
                line-height: 2.5rem;
            }

            .selected {
                background-color: #36a9ce;
                color: #FFFFFF;
            }
        }

    }

    .headerImg {
        width: 100%;
        overflow: hidden;

        .el-carousel {
            aspect-ratio: 96 / 35;
            overflow: hidden;
        }

        .el-carousel__item h3 {
            color: #475669;
            font-size: 18px;
            opacity: 0.75;
            line-height: 300px;
            margin: 0;
            overflow: hidden;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    .introduceOuter {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 2rem;

        .introduceName {
            text-align: start;
            color: #36a9ce;
            font-size: 2rem;
            font-weight: bold;
        }

        .introduceLine {
            border: 1px solid #36a9ce;
            width: 9rem;
            margin: 1rem 0;
        }

        .introduceContent {
            color: #626262;
            font-size: 1.1rem;
            width: 41rem;
            line-height: 3rem;
            text-align: start;
        }

        .aboutMoreButton {
            color: #36a9ce;
            font-size: 1.2rem;
            border: 2px solid #36a9ce;
            height: 3.5rem;
            width: 10.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            cursor: pointer;
        }

        img {
            aspect-ratio: 501 / 382;
            width: 30rem;
            margin-right: 3rem;
            margin-left: 8rem;
        }
    }

    .addressHeader {
        background-image: url("../static/image/background.png");
        width: 100%;
        aspect-ratio: 1920 / 431;
        margin-top: 8rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .addressTop {
            margin-top: 20rem;
        }
    }

    .mapOuter {
        width: 70%;
        margin: 6rem 15% 0 15%;
        aspect-ratio: 1272/560;
        pointer-events: none; //阻止鼠标事件
        cursor: not-allowed; //禁用鼠标样式

        .mapContainer {
            height: 100%;
            width: 100%;
        }
    }

    .contactOuter {
        margin-top: 6rem;
    }

    .bottomOuter {
        background-color: #212832;
        height: 14rem;
        color: #CACACA;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottomContent {
            display: flex;
            flex-direction: row;
            margin-top: 5rem;
            width: 100%;
            justify-content: center;

            .bottomIcon {
                font-size: 1.2rem;
                margin-right: 0.5rem;
            }

            .contentText {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 12%;
            }
        }

        .bottomName {
            margin-top: 3rem;
        }
    }
}
</style>
<style lang="scss">
::-webkit-scrollbar {
    width: 8px;
}

/*定义滚动条 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #b3bac4;
}

/*定义滚动条滑动轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #8f8f90;
}
</style>